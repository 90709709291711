.card-overlay{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 34343445435;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
}
.close-btn{
    background-color:transparent;
    border: none;
    position:absolute;
    right:10px;
    top:10px;
    color:#fff;
    z-index: 99;
}
.close-btn img{
    width: 35px;
    height: 35px;
}
.close-btn:hover{
    cursor: pointer;
}
.close-btn:focus{
    outline: none;
}