.gallery-card-content-name {
  font-family: 'poppins-semibold' !important;
}

.gallery-card-content-id-box span {
  font-family: 'poppins' !important;
}

.gallery-card-content-id-box p {
  font-family: 'bau' !important;
}

.gallery-card-price-holder {
  margin: 0 !important;
}

.gallery-grid-container {
  gap: 12px !important;
}

.gallery-card-item {
  flex-basis: 23.4%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 23.4%;
}


@media screen and (max-width: 1100px) {
  .gallery-card-item {
    flex-basis: 31.5%;
    max-width: 31.5%;
  }
}


@media screen and (max-width: 900px) {
  .gallery-card-item {
    flex-basis: 45%;
    max-width: 45%;
  }
}

@media screen and (max-width: 600px) {
  .gallery-card-item {
    flex-basis: 100%;
    max-width: 100%;
  }
}