.not-found-container{
    text-align: center;
    font-size: 16px;
}
.not-found-container span{
    /* font-size: 350px; */
    font-size: 17em;
    line-height: 1em;
    /* line-height: 350px; */
    /* height: 200px; */
    font-family: 'citrusgothic'
}

@media only screen and (max-width: 1525px) {
    .not-found-container{
        font-size: 12px;
    }
}