
.buttonHolder{
  position: fixed;
  height: 16px;
  width: 16px;
  top: 4%;
  left: 4%;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: #fff;
  transition: color .3s ease-in-out;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
.nextBox:hover .titleText {
  font-family: 'poppins-medium';
      color: #fe2c85;
}

.previousBox:hover .prevtitleText {
  font-family: 'poppins-medium';
      color: #fe2c85;
}
}