.card-root {
  position: relative;
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgba(161, 161, 161, 0.48);
}
.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px 0px
}
.image-container:hover .card-details{
  opacity: 1;
  transition: all .25s;
}
.image-container img{
  max-width: 100%;
  display: block;
  width: 100%;
}
.card-content {
  width: 100%;
  margin-top: 0px;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 15px 15px;
  padding: 20px 25px 25px;
  display:flex;
  flex-direction: column;
}
.card-content p{
  margin-bottom: 0;
  color:#000;
}
.card-title{
  font-size: 14px;
  font-family: 'poppins';
}
.card-name{
  font-family: 'poppins-semibold';
  font-size: 18px;
  min-height: 50;
  min-height: 40px;
}
.card-id{
  font-size: 18px;
  font-family: 'poppins-semibold';
  min-height: 40px;
}
.card-details::-webkit-scrollbar{
  width:0 !important;
}

.card-details{
    opacity: 0;
    transition: all .25s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ea;
    z-index: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 20px;
    overflow-y: auto;


}
.card-details ul{
  list-style: none;
  padding: 0;
  text-align: center;
}
.trait-type, .trait-value{
  margin: 0;
}
.trait-type{
  color:rgb(112, 112, 112)
}
.trait-value{
  color: #fff;
}

.card-details::-webkit-scrollbar-thumb {
  background: none
}
.col-lg-3{
  width: 33.3% !important;
}
@media (min-width: 992px) {
  .col-lg-3{
    flex: 0 0 33.3% !important;
    max-width: 33.3% !important;
  }}
@media (max-width: 730px) {
  .col-lg-3{
    width: 50% !important;
  }}
@media (max-width: 500px) {
  .col-lg-3{
  width: 100% !important;
}}