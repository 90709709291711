/* .custom-filter-select select{
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	border: none;
	background: transparent;
	box-shadow: none;
	color: #000;
	outline: none;
  }

  .select-parent{
	border: 2px solid #000;
	.custom-filter-select
	padding-right: 5px;
  }
  .select-parent:focus-within{
	border: 2px solid #fe2c85;
  }

.filter-container select{

    font-size: 16px;
}
 */

 .custom-filter-select{
	cursor: pointer;
	min-width: 100px;
	display: flex;
	flex-direction: column;
	margin: 0.5rem;
	align-items: flex-start;
 }

.filter-container label{
	color: #000;
    font-size: 14px;
	font-family: 'poppins' !important;
	cursor: pointer;
	text-transform: uppercase;
}

.filter-container label span.arrow {
	font-size: 12px;
	
}

.filter-container label.open span.arrow {
	color:#fe2c85;
}

.custom-filter-select{
	position: relative;
}


.custom-filter-select .filter-dropdown{
	max-height:300px;
	overflow-y: auto;
	color: #000;
    font-size: 14px;
    font-weight: 400;
	text-align: left;
	padding:1rem;
	position: absolute;
	display: none;
	top: 67px;
	left: 0px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0 8px rgba(0,0,0,0.5);
	z-index: 5;

}

.custom-filter-select .filter-dropdown::-webkit-scrollbar {
    width: 4px!important;
    height: 8px;
  }
.custom-filter-select .filter-dropdown::-webkit-scrollbar-track {
background: transparent;
} 
/* Handle */
.custom-filter-select .filter-dropdown::-webkit-scrollbar-thumb {
background: rgb(252, 108, 3);
border-radius: 10px;
}

.filter-container label.open + .filter-dropdown{
	display: block;
}

.filter-value {
	color:#fe2c85;
	font-size: 16px;
	font-weight: 600;
	font-family: 'poppins-semibold';
	text-align: left;
}
.cust-filter-item{
	white-space:nowrap;
	margin:5px 0px;
}
.cust-filter-item.selected{
	font-weight: 600;
}
.cust-filter-item:hover{
	font-weight: 600;

}