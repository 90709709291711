.filter-container{
    position: sticky;
    position: -webkit-sticky;
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    padding: 0px 28px;
    top: 124px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 29%);
    max-width: 1140px;
    border-radius: 10px;
    z-index: 3;
}

.filter-container-inner{
  margin: 10px 10px 10px 0px;
  display:flex;
  flex-wrap: wrap;
  width: 85%
}

.filter-container-inner .the-filters{
  display: flex;
  flex-wrap: wrap;
}

.id-input-filter{
    display: flex;
    align-items: center;
}

.id-input-filter input{
    background: transparent;
    color:black;
    border: none;
    outline: none;
    max-width: 88px;
    font-weight: 600;
    font-size: 16px;
}

.id-input-filter input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fe2c85;
    opacity: 1; /* Firefox */
  }
  
  .id-input-filter input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fe2c85;
  }
  
  .id-input-filter input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fe2c85;
  }
.explorerTitle{
  color: #000;
  padding-top: 20px;
  text-align: center;
}
.filter-container-sticky{
  position: sticky;
  padding: 0px 20px;
  top: 124px;
  z-index: 3;
}