.MuiFormControl-root .MuiFormLabel-asterisk {
  display: none;
}

.MuiPaper-root {
  background-color: black !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
}

.MuiInputLabel-shrink {
  padding-left: 5px;
  .MuiTypography-main {
    font-size: 16px !important;
    opacity: 1;
  }
  .MuiTypography-main:focus {
    color: #fe2c85;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #3b424c inset !important;
  transition: color 5000s ease-in-out 0s;
  transition-delay: color 5000s;
}

.MuiInputBase-root {
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.23);
  }
  .MuiSvgIcon-root {
    fill: #fff !important;
    scale: 1.3;
  }
}

.Mui-focused {
  .MuiTypography-main {
    color: #fe2c85;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #fe2c85 !important;
  }
}
